import React from "react";
import { Link } from "react-router-dom";

const HeaderTop = () => {
  return (
    <React.Fragment>
      {/* HEADER TOP AREA */}
      <div className="rts-ht rts-ht__bg">
        <div className="container">
          <div className="row">
            <div className="rts-ht__wrapper">
              <div className="rts-ht__links">
                <div className="rts-ht__email">
                  <a href="tel:+917890090500">
                    <img
                      src="assets/images/icon/phone.svg"
                      alt=""
                      className="icon"
                      width={20}
                    />{" "}
                    +91 7890-090-500
                  </a>
                </div>
                <div className="rts-ht__email">
                  <a href="mailto:helpdesk@formaxpay.com">
                    <img
                      src="assets/images/icon/email.svg"
                      alt=""
                      className="icon"
                    />
                    helpdesk@formaxpay.com
                  </a>
                </div>
              </div>
              {/* <div className="rts-ht__promo">
                <p>
                  <img
                    className="icon"
                    src="assets/images/icon/tag--group.svg"
                    alt=""
                  />{" "}
                  
                </p>
              </div> */}
              <div className="rts-ht__links">
                <div className="live-chat-has-dropdown">
                  <a
                    href="https://api.whatsapp.com/send?phone=917890090500&amp;text=https://wa.me/917890090500?text=Hi Formax Pay Team, I need some help."
                    target="_blank"
                    className="live__chat"
                  >
                    <img
                      src="assets/images/icon/forum.svg"
                      alt=""
                      className="icon"
                    />
                    Live Chat
                  </a>
                </div>
                {/* <div className="login-btn-has-dropdown">
                  <a
                    href="https://app.formaxpay.com/"
                    target="_blank"
                    className="login__link"
                  >
                    <img
                      src="assets/images/icon/person.svg"
                      alt=""
                      className="icon"
                    />
                    Login
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* HEADER TOP AREA END */}
    </React.Fragment>
  );
};

export default HeaderTop;
