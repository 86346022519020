import React from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";

const Footer = () => {
  return (
    <React.Fragment>
      <>
        {/* FOOTER AREA */}
        <footer className="rts-footer site-footer-one section__padding">
          <div className="container">
            <div className="row">
              {/* widget */}
              <div className="col-lg-4 col-md-5 col-sm-6 rts-footer__widget--column">
                <div className="rts-footer__widget footer__widget w-350">
                  <a
                    href="/"
                    aria-label="main page link"
                    className="footer__logo"
                  >
                    <img src="/images/logo.png" alt="logo" width={170} />
                  </a>
                  <p className="brand-desc">
                    Formax Pay simplifies digital payments with services like
                    recharge, utility payments, and e-governance. We also offer
                    API solutions to help businesses streamline financial
                    operations.
                  </p>
                  <div className="separator site-default-border" />
                  <div className="social__media">
                    <h5>social media</h5>
                    <div className="social__media--list">
                      <a
                        href="https://www.facebook.com/formaxitsolutions"
                        aria-label="social-link"
                        target="_blank"
                        className="media"
                      >
                        <i className="fa-brands fa-facebook-f" />
                      </a>
                      <a
                        href="https://x.com/formax_it"
                        aria-label="social-link"
                        target="_blank"
                        className="media"
                      >
                        <i className="fa-brands fa-x-twitter" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/formax-pay"
                        aria-label="social-link"
                        target="_blank"
                        className="media"
                      >
                        <i className="fa-brands fa-linkedin" />
                      </a>
                      <a
                        href="https://www.instagram.com/formaxpay"
                        aria-label="social-link"
                        target="_blank"
                        className="media"
                      >
                        <i className="fa-brands fa-instagram" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* widget end */}
              {/* widget */}
              <div className="col-lg-2 col-md-3 col-sm-6 rts-footer__widget--column">
                <div className="rts-footer__widget footer__widget extra-padding">
                  <h5 className="widget-title">Company</h5>
                  <div className="rts-footer__widget--menu ">
                    <ul>
                      <li>
                        <a href="/about-formax-pay">About Us</a>
                      </li>
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li>
                        <a href="/terms-and-conditions">Terms and Conditions</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/refund-policy">Refund Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* widget end */}
              {/* widget */}
              <div className="col-lg-3 col-md-4 col-sm-6 rts-footer__widget--column">
                <div className="rts-footer__widget footer__widget extra-padding">
                  <h5 className="widget-title">Our Services</h5>
                  <div className="rts-footer__widget--menu ">
                    <ul>
                      <li>
                        <a href="/mobile-and-dth-recharge">Mobile Recharge</a>
                      </li>
                      <li>
                        <a href="/mobile-and-dth-recharge">DTH Recharge</a>
                      </li>
                      <li>
                        <a href="/utility-bill-payments">
                          Utility Bill Payments
                        </a>
                      </li>
                      <li>
                        <a href="/pan-card-service">PAN Card Service</a>
                      </li>
                      <li>
                        <a href="/pan-card-service">Insurance</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* widget end */}
              {/* widget */}
              {/* <div className="col-lg-2 col-md-6 col-sm-6 rts-footer__widget--column">
                <div className="rts-footer__widget footer__widget">
                  <h5 className="widget-title">Quick Links</h5>
                  <div className="rts-footer__widget--menu">
                    <ul>
                      <li>
                        <a
                          href="https://app.formax.cloud/#/login"
                          target="_blank"
                        >
                          Login
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://app.formax.cloud/#/signup"
                          target="_blank"
                        >
                          Sign Up
                        </a>
                      </li>
                      <li>
                        <a href="/bulk-sms-pricing">Pricing</a>
                      </li>
                      <li>
                        <a href="https://docs.formax.cloud" target="_blank">
                          Developer API
                        </a>
                      </li>
                      <li>
                        <a href="/online-payment" target="_blank">
                          Online Payment
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              {/* widget end */}
              {/* widget */}
              <div className="col-lg-3 col-md-6 rts-footer__widget--column">
                <div className="rts-footer__widget footer__widget">
                  <h5 className="widget-title">Contact Us</h5>
                  <p class="brand-desc address">
                    Formax It Solutions Pvt Ltd, <br /> Ground Floor, 16,
                    Mochpole, Near Junior School, Nabapally, Barasat, Kolkata -
                    700126
                  </p>
                  <div class="separator site-default-border"></div>

                  <div class="contact-method">
                    <a href="tel:+917890090500">
                      <span>
                        <i class="fa-light fa-phone"></i>
                      </span>
                      +91 7890-090-500
                    </a>
                    <a href="mailto:helpdesk@formaxpay.com">
                      <span>
                        <i class="fa-light fa-envelope"></i>
                      </span>
                      helpdesk@formaxpay.com
                    </a>
                  </div>
                </div>
              </div>
              {/* widget end */}
            </div>
            <p style={{ paddingTop: "30px" }}>
              CIN: U72900WB2018PTC228240 | GSTIN: 19AADCF5125F1ZT{" "}
            </p>
          </div>
          <div className="container">
            <div className="row">
              <div className="rts-footer__copyright text-center">
                <p>
                  Copyright © {new Date().getFullYear()} Formax IT Solutions
                  Private Limited | All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </footer>
        {/* FOOTER AREA END */}

        <MobileMenu />

        {/* THEME PRELOADER START */}
        <div className="loader-wrapper">
          <div className="loader"></div>
          <div className="loader-section section-left" />
          <div className="loader-section section-right" />
        </div>
        {/* THEME PRELOADER END */}

        {/* BACK TO TOP AREA START */}
        {/* <div className="progress-wrap">
          <svg
            className="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path
              d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
              style={{
                transition: "stroke-dashoffset 10ms linear 0s",
                strokeDasharray: "307.919, 307.919",
                strokeDashoffset: "307.919",
              }}
            />
          </svg>
        </div> */}
        {/* BACK TO TOP AREA EDN */}
      </>
    </React.Fragment>
  );
};

export default Footer;
